import React from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'

interface ContactProps {
  link?: string
  linkTitle?: string
  title: string
}

const Contact: React.FC<ContactProps> = ({ link, linkTitle, title }) => {
    const { t, ready } = useTranslation()

    const defaultLink = link || '/become-a-partner'
    const defaultLinkTitle = linkTitle || (ready ? t('Learn More') : 'Learn More')

    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('ContactCustomTop', error)}>
            {ready && (
                <section id="contact" className="payment-processing-contact">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="contact-connect-custom">
                                    <h3>{title}</h3>
                                    {/*<a href="#" className="btn btn-primary">Browse Vehicle</a>*/}
                                    {/*<p>No setup costs or contract - start taking payments today</p>*/}
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div style={{ marginTop: '-10px' }} className="contact-connect-custom">
                                    <a
                                        href={`${process.env.NEXT_PUBLIC_REACT_APP_DASHBOARD_URL}/#/register`}
                                        className="btn btn-primary"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {t('Sign up')}
                                    </a>
                                    <a
                                        href={`${process.env.NEXT_PUBLIC_REACT_APP_DASHBOARD_URL}/#/login`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="talk-to-sales"
                                    >
                                        {t('Login')}
                                    </a>
                                    <Link href={defaultLink} passHref legacyBehavior>
                                        <a className="talk-to-sales">{defaultLinkTitle}</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </ErrorBoundary>
    )
}

export default Contact
