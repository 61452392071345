import React from 'react'
import { useTranslation } from 'next-i18next'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'

const FiveReasons = () => {
    const { t, ready } = useTranslation()

    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('FiveReasons', error)}>
            {ready && (
                <section id="why-simplybook" className="ux-research-process ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-12">
                                <div className="research-process-content">
                                    <h2 className="black-font">
                                        {t('5 REASONS TO HIRE YOUR VEHICLE WITH SIMPLYBOOK')}
                                    </h2>
                                    <div className="single-process">
                                        <span>1</span>
                                        <p>{t('research_top_paragraph_1')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>2</span>
                                        <p>{t('research_top_paragraph_2')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>3</span>
                                        <p>{t('research_top_paragraph_3')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>4</span>
                                        <p>{t('research_top_paragraph_4')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>5</span>
                                        <p>{t('research_top_paragraph_5')}</p>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="research-process-content">
                                    <h2 className="black-font">
                                        {t('WHAT DO OUR PARTNER RENTAL AGENCIES HAVE TO OFFER?')}
                                    </h2>
                                    <div className="single-process">
                                        <span>1</span>
                                        <p>{t('research_top_paragraph_6')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>2</span>
                                        <p>{t('research_top_paragraph_7')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>3</span>
                                        <p>{t('research_top_paragraph_8')}</p>
                                    </div>

                                    <div className="single-process">
                                        <span>4</span>
                                        <p>{t('research_top_paragraph_9')}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-12">
            <div className="research-process-image">
              <Image
                src="/images/assets/woman-rental-car.jpeg"
                alt="woman in rental car doing thumbs up"
               fill
              />
            </div>
          </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 style={{ marginTop: '30px', fontWeight: '500', fontSize: '20px' }}>
                                    {t('Support the family businesses of your favourite holiday destinations!')}
                                    <a
                                        target="_blank"
                                        className="read-more-link"
                                        rel="noopener noreferrer"
                                        href="https://www.simplybook.com/blog/experience-greece/people/support-small-local-companies"
                                    >
                                        {t('Read More')}
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </ErrorBoundary>
    )
}

export default FiveReasons
