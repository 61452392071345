import React from 'react'
import Navbar from '../src/components/theme/Layouts/NavbarSimplybook'
import Footer from '../src/components/theme/Layouts/Footer'
import Banner from '../src/components/theme/Blocks/Banner'
import ResearchTop from '../src/components/theme/Blocks/FiveReasons'
import VehiclesVariety from '../src/components/theme/Blocks/VehiclesVariety'
import Testimonials from '../src/components/theme/Blocks/Testimonials'
import PopularDestinations from '../src/components/theme/Blocks/PopularDestinations'
import ContactTop from '../src/components/theme/Blocks/ContactCustomTop'
import Contact from '../src/components/theme/Blocks/ContactCustom'
import { DestinationWidgetProps, HomePageProps, PreFilledMapPlacesType } from '../src/types'
import { getGraphcmsDestinations } from '../src/utils/graphcms-utils'
import { NextPage } from 'next'
import { prefilledDestinationsVar } from '../src/cache/cache'

import { useTranslation } from 'next-i18next'
import { MainPageMetaTag } from '../src/components/Metatags/tags'
import { CombinedState } from 'redux'
import { useSelector } from 'react-redux'
import { getPrefilledPlaceFromUrl } from '../src/utils/googleMaps'


const App: NextPage<HomePageProps> = ({ destinationsWidget, prefilledDestinations, searchConfig }) => {

    const { t,ready } = useTranslation()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useSelector((reduxStore: CombinedState<any>) => {
        reduxStore.sessionReducer.vtype = searchConfig.type
    })


    const tempPlaces = {}
    prefilledDestinations?.forEach((place: PreFilledMapPlacesType) => {
        tempPlaces[place.title.toLowerCase()] = {
            description: place?.preFillLocationDescription,
            place_id: place?.preFillGooglePlaceId,
            lat: place?.preFillLat,
            lng: place?.preFillLong,
            id: '',
        }
    })
    prefilledDestinationsVar(tempPlaces)

    return (
        <>
            <MainPageMetaTag />
            <Navbar withoutBanner={false} />
            <Banner />
            {ready && <ContactTop title={t('Are you a vehicle rental agency?')} />}
            <ResearchTop />
            <VehiclesVariety />
            <PopularDestinations destinationsWidget={destinationsWidget} />
            <Testimonials />
            <Contact />
            <Footer destinationsWidget={destinationsWidget} />
        </>
    )
}

export async function getServerSideProps(context) {
    const destinationsWidget: DestinationWidgetProps[] = await getGraphcmsDestinations()
    // const prefilledDestinations: PreFilledMapPlacesType[] = await getGraphcmsPreFilledMapPlaces()
    const { type, location } = context.query
    const searchConfig = {
        'location': location === undefined ? '' : location,
        'type': type === undefined ? '' : type,
    }
    const prefilledDestinations = []
    if (searchConfig.location) {
        const place = await getPrefilledPlaceFromUrl(searchConfig.location)
        if (place) prefilledDestinations.push(place)

    }

    return {
        props: {
            destinationsWidget,
            prefilledDestinations,
            searchConfig,
        },
    }
}

export default App
