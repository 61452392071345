import React from 'react'
import Slider from 'react-slick'
import { useTranslation } from 'next-i18next'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'

interface Testimonial {
  text: string
  name: string
  country: string
}

const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 2040,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            },
        },
    ],
}

const testimonials: Testimonial[] = [
    {
        text: 'Awesome service, very friendly staff and flexible services. Easy communication and good customer service. Cars in good shape. I would definitely choose this company for my next trip in Crete.',
        name: 'Vaidas Vinslovas',
        country: 'Lithuania',
    },
    {
        text: 'Certainly, a car rental to recommend. Everything perfect and honest friendly and helpful. they also provided us with a map and directions on where to turn. New machine and competitive price. Thanks especially to Dimitrios who followed us.',
        name: 'Fabio & Antonella',
        country: 'Italy',
    },
    {
        text: 'Very reliable and professional service George is very professional, has new and well kept cars, used his service several time and he always was on time and available, can advise abouot the car you need and ready to welcome you at any hour of the day or night.',
        name: 'Kadishshinoba Kfal Saba',
        country: 'Israel',
    },
    {
        text: 'Best Scooter Rent in Agios Nikolaos Super Service and cheap price! Thanks for our good trip with your scooter! We rent it for a longer time and there was no problem with it.',
        name: 'Puddue Lisabetta',
        country: 'Italy',
    },
    {
        text: 'We stayed in Kolympia so just walked in to rent a car based on the positive reviews. We initially rented for 4 days but extended one day without a problem. It is really cheap and good cars to explore the island. Would recommend to anyone!',
        name: 'Silvia van H',
        country: 'Netherlands',
    },
    {
        text: 'Efficient, friendly and very helpful service throughout the duration of the rental. The staff were particularly helpful as we had to extend rental and change drop off point at short notice due to the demise of Thomas Cook. Will certainly book with CR the next time I return to Crete.',
        name: 'Andy Sy',
        country: 'UK',
    },
    {
        text: 'I came to Mytilene all by myself from Australia and was so glad I found this company. Very professional and friendly service. Highly recommend renting from LE. The car was clean and in very good condition. I was even offered a lift to the airport. Incredible service and great price. Thank you.',
        name: 'Annie Peros',
        country: 'Australia',
    },
    {
        text: 'The staff are great and friendly and the prices are really good. Would definitely recommend!',
        name: 'Ollie Scott',
        country: 'UK',
    },
]

const Testimonials = () => {
    const { t, ready } = useTranslation()
    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('Testimonials', error)}>
            {ready && (
                <section id="testimonials" className="feedback-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t('Reviews of our partner rental agencies')}</h2>
                        </div>

                        <Slider {...settings}>
                            {testimonials.map((testimonial, index) => (
                                <div className="col-lg-12 col-md-12" key={index}>
                                    <div className="feedback-item" key={index}>
                                        <p>{testimonial.text}</p>
                                        <div className="client-info">
                                            <h3>{testimonial.name}</h3>
                                            <span>{testimonial.country}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </section>
            )}
        </ErrorBoundary>
    )
}

export default Testimonials
