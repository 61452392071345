import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Slider from 'react-slick'
import { useTranslation } from 'next-i18next'
import { ColorTypes, DestinationWidgetProps, PopularDestinationsWidgetProps } from '../../../types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../ErrorComponent'

const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 2040,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            },
        },
    ],
}

const PopularDestinations: React.FC<PopularDestinationsWidgetProps> = ({ destinationsWidget }) => {
    const { t, ready } = useTranslation()
    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('PopularDestinations', error)}>
            <>
                {ready && (
                    <section id="services" className="services-area ptb-100">
                        <div className="container">
                            <div className="lead-generation-section-title">
                                <h1>{t('Popular Destinations')}</h1>
                                <div className="bar"></div>
                            </div>

                            <Slider {...settings}>
                                {destinationsWidget?.map((des: DestinationWidgetProps) => {
                                    const imgUrl = des?.widgetImage?.url || '/images/landings/paros-menu.jpg'
                                    return (
                                        <div className="col-lg-12 col-md-12" key={des?.id}>
                                            <Link href={`/rent/car/${des?.title.toLocaleLowerCase()}`} passHref>
                                                <div className="services-box">
                                                    <Image
                                                        src={imgUrl}
                                                        alt={des?.widgetAltText}
                                                        layout="responsive"
                                                        width={360}
                                                        height={240}
                                                    />
                                                    <div className="services-content">
                                                        <h2 className="black-font">
                                                            {des?.title?.charAt(0).toUpperCase() + des?.title?.slice(1)}
                                                        </h2>
                                                        <ul>
                                                            <li className={ColorTypes[des?.widgetAttributionColor]}>
                                                                {t('Photo by:')}
                                &nbsp;
                                                                {des?.widgetAttribution}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </section>
                )}
            </>
        </ErrorBoundary>
    )
}

export default PopularDestinations
